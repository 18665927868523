$bv-dropdown-text-defined: false !default;

@if $bv-dropdown-text-defined == false {
  // This test will only include these style definitions once
  $bv-dropdown-text-defined: true;

  // Custom styles for <b-dropdown-text>
  // Based on class `.dropdown-item`
  .b-dropdown-text {
    display: inline-block;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    margin-bottom: 0;
    width: 100%;
    clear: both;
    font-weight: $font-weight-lighter;

    &:first-child {
      @include border-top-radius($dropdown-inner-border-radius);
    }

    &:last-child {
      @include border-bottom-radius($dropdown-inner-border-radius);
    }
  }
}
