// --- BootstrapVue Utility Classes ---

$bv-utility-classes-defined: false !default;

// Make sure to include these style definitions only once
@if $bv-utility-classes-defined == false {
  $bv-utility-classes-defined: true;

  // Create `.bv-d-<bp>-down-none` helper classes
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($breakpoint) {
      .bv-d-#{$breakpoint}-down-none {
        display: none !important;
      }
    }
  }
}
