$bv-dropdown-defined: false !default;

// Make sure to include these style definitions only once
@if $bv-dropdown-defined == false {
  $bv-dropdown-defined: true;

  // Hide the caret for `no-caret` setting
  // See: https://github.com/bootstrap-vue/bootstrap-vue/issues/1473
  // See: https://github.com/twbs/bootstrap/issues/23724
  .dropdown {
    &:not(.dropleft) {
      .dropdown-toggle {
        &.dropdown-toggle-no-caret::after {
          display: none !important;
        }
      }
    }

    // See: https://github.com/bootstrap-vue/bootstrap-vue/issues/2909
    &.dropleft {
      .dropdown-toggle {
        &.dropdown-toggle-no-caret::before {
          display: none !important;
        }
      }
    }
  }
}
