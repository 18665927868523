// --- Alerts ---
$bv-alert-transition: $transition-fade !default;

// --- Tables ---
$b-table-busy-opacity: 0.55 !default;

// --- Toasts ---
$b-toaster-zindex: 1100 !default;
$b-toaster-offset-top: 0.5rem !default;
$b-toaster-offset-bottom: $b-toaster-offset-top !default;
$b-toaster-offset-left: $b-toaster-offset-top !default;
$b-toaster-offset-right: $b-toaster-offset-top !default;

$b-toast-bg-level: $alert-bg-level !default;
$b-toast-border-level: $alert-border-level !default;
$b-toast-color-level: $alert-color-level !default;
